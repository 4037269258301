import * as Sentry from "@sentry/vue";

export function extractQueryParameters() {
    return new URLSearchParams(window.location.search)
}

export const GEOLOCATION_PERMISSION_PROMPT = 'prompt'
export const GEOLOCATION_PERMISSION_GRANTED = 'granted'
export const GEOLOCATION_PERMISSION_DENIED = 'denied'

export const AUTH_STEP = 0
export const MAIN_INFO_STEP = 1
export const ASK_FOR_GEOLOCATION_PERMISSIONS_STEP = 2
export const REGISTER_STEP = 3
export const SUCCESS_STEP = 4
export const GEOLOCATION_PERMISSIONS_DENIED_STEP = 5;

const FETCH_ERROR_MESSAGE = 'Error de conexión, intente más tarde';

export const CLASS_2_REGISTER_STRUCT = {
    occurrenceId: 0,
    courseName: '',
    campusLocation: '',
    profesorName: '',
    section: '',
    classDate: '',
    module: '',
    activityName: '',
    groupName: '',
    previousRegister: Number,
    url: '',
};

export function getLocation() {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve({latitude: position.coords.latitude, longitude: position.coords.longitude})
            }, (error) => {
                Sentry.captureException(error, {extra: {key: "getLocation" }})
                reject(error)
            }
        )
    })
}

export async function auth(username, password) {
    if(useMock()) { return await mockAuth() }
    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({name: username, password: password, type: 'WEB'})
    }
    const captureContext = { extra: { key: "auth" }}
    const authEndpointUrl = `${process.env.VUE_APP_API_ROOT}/user-api/students`;
    const response = await fetch(authEndpointUrl, requestOptions)
        .catch((error) => {
            error.message = FETCH_ERROR_MESSAGE
            Sentry.captureException(error, captureContext)
            throw error
        })
    if (200 === response.status) {
        const json = await response.json()
        if (json.status) {
            return json.data
        } else {
            const error = {message: json.message ?? 'Usuario / Contraseña incorrecta', code: json.errorCode ?? 'invalid_credentials'};
            Sentry.captureException(error, captureContext)
            throw error
        }
    } else {
        const error = {message: 'Error al intentar autenticación'};
        Sentry.captureException(error, captureContext)
        throw error
    }
}

export async function getStudentCurrentClassInfo(token, occurrenceId, classroomCode) {
    if(useMock()) { return await mockGetStudentCurrentClassInfo() }
    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type": "application/json", "x-access-token": token},
        body: JSON.stringify({occurrenceId, classroomCode})
    }

    const captureContext = { extra: { key: "getStudentInfo"}};
    const response = await fetch(`${process.env.VUE_APP_API_ROOT}/class-api/student/classes/getStudentCurrentClassInfo`, requestOptions)
        .catch((error) => {
            error.message = FETCH_ERROR_MESSAGE
            Sentry.captureException(error, captureContext)
            throw error
        })
    const json = await response.json()
    if (200 === response.status) {
        if (json.status) {
            return json.data[0]
        } else {
            const error = {message: json.message, code: json.errorCode ?? 'no_class_info'};
            Sentry.captureException(error, captureContext)
            throw error
        }
    } else {
        const error = {message: json?.message ?? `Error http: ${response.status}`};
        Sentry.captureException(error, captureContext)
        throw error
    }
}

export async function register(token, occurrenceId, classroomCode, position, staticQr) {
    if(useMock()) { return await mockRegister() }
    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type": "application/json", "x-access-token": token},
        body: JSON.stringify({occurrenceId, classroomCode, position, staticQr})
    }

    const captureContext = { extra: { key: "updateAssistance" } }

    const response = await fetch(`${process.env.VUE_APP_API_ROOT}/class-api/student/attendance/updateStudentAttendanceQR`, requestOptions)
        .catch(
            (error) => {
                error.message = FETCH_ERROR_MESSAGE
                Sentry.captureException(error, captureContext)
                throw error
            }
        )
    const json = await response.json()
    if (200 === response.status) {
        if (json.status) {
            return json.data[0]
        } else {
            const error = {message: json.message, code: json.errorCode ?? 'data_error'};
            Sentry.captureException(error, captureContext)
            throw error
        }
    } else {
        const error = {message: json?.message ?? `Error http: ${response.status}`, code: 'data_error'};
        Sentry.captureException(error, captureContext)
        throw error
    }
}

function useMock() {
    let useMock = 'true' === process.env.VUE_APP_MOCK_APIS;
    console.log(`useMock: ${useMock}`)
    return useMock
}

function mockAuth() {
    const error = null
    // const error = {message: 'Error al conectar con WebService cliente', code: 100 }
    // const error = {message: 'Credenciales incorrectas', code: 101 }
    // const error = {message: 'Error al enviar datos', }
    if(error) {
        Sentry.captureException(error, { extra: { key: "mockAuth" }})
        throw error
    }
    return new Promise((resolve) => resolve(
        {
            data: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VycyI6eyJlbWFpbCI6ImFsZWphbmRyby5zYW5odWV6YUB1cGxhbm5lci5jb20iLCJuYW1lIjoiZXh0dXBsYW5uZXIifSwidHlwZSI6IndlYiIsImlhdCI6IjIwMjMtMDMtMDdUMjE6NDU6MjAuMzQ4WiIsImp0aSI6ImE3NTMwZTIxLTgyMTUtNDhmMi1iMzg2LTJhMTc3ZjI0NTJlNyIsImV4cCI6MTY3ODIyNjQyMH0.jLnRI2F6nu9zezzh2MOwgZMTivt-k5P3oyEwLcWkD6U",
            username: 'Juan Pérez'
        }
    ))
}

function mockGetStudentCurrentClassInfo() {
    return new Promise((resolve) => resolve({
        "occurrenceId": 6352054,
        "courseName": "UPL034 - Orientación educativa",
        "campusLocation": "UH-21 / Monterrico",
        "profesorName": "Francisco Véliz, Docente 1000, Docente 1",
        "section": "86890/86890",
        "classDate": "Jueves 03/02/2022",
        "module": "18:30 - 18:59",
        "activityName": "TPR",
        "groupName": "1",

        // Para probar error 'Código QR no válido dar a "date" cualquier valor distinto a null
        "date": null,
        "time": null
    }))

    // Para probar error 'Sin clase asignada'
    // return new Promise((resolve) => resolve(null))

    // Para probar error 'No se pueden obtener datos de la clase'
    // throw {message: 'Error', code: 'no_class_info'}
    // throw {message: 'Error XXX', code: 'data_error'}
}

function mockRegister() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ ok: true });
        }, 300);
    })
}

/*export async function validate(token) {
    if(useMock()) { return await mockValidate() }
    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type": "application/json", "x-access-token": token}
    }
    const response = await fetch(`${process.env.VUE_APP_API_ROOT}/user-api/validate`, requestOptions)
        .catch((error) => Sentry.captureException(error, {
            extra: {
            key: "validate"
            }
        }))
    const json = await response.json()
    if (200 === response.status) {
        if (json.status) {
            return {user: {name: json.data.user.name}, token: json.data.token}
        } else {
            throw {message: json.message}
        }
    } else {
        throw {message: json?.message ?? `Error http: ${response.status}`}
    }
}

function mockValidate() {
    return new Promise((resolve) => resolve({token: "", user: {name: "Juan Pérez"}}))
}*/
