import { createApp } from 'vue'
import App from './App.vue'
import { createSentry } from './common/sentry';

import '@/assets/scss/bootstrap-custom.scss'
import '@/assets/global.scss'

const app = createApp(App);
createSentry(app);
app.config.globalProperties.$clientName = process.env.VUE_APP_CLIENT_NAME || 'Universidad Andrés Bello'
app.config.globalProperties.$clientCode = process.env.VUE_APP_CLIENT_CODE || 'unab'
app.mount('#app')