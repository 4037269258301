<template>
  <div class="panel border shadow-sm rounded-3 p-0 login"><!-- panel -->

    <div class="toasts">
      <div class="toast bg-danger text-white text-center" v-bind:class="{'show': error?.message}">{{error?.message}}</div>
    </div>

    <div class="panel-body px-4 pb-5"><!-- panel-body-->
      <div class="text-center my-5">
        <h3>Bienvenido</h3>
        <p class="pt-1">Ingresa tus datos de acceso</p>
      </div>
      <form @submit.prevent="next">
        <div class="pb-4">
          <label for="username" class="form-label">Email</label>
          <input class="form-control" placeholder="Email" id="username" type="text" v-model="username">
        </div>
        <div class="pb-2">
          <label for="password" class="form-label">Contrase&ntilde;a</label>
          <input class="form-control" placeholder="*******" id="password" type="password" v-model="password">
        </div>
        <div class="d-grid mt-4">
          <button class="btn btn-primary" type="submit">Ingresar</button>
        </div>
      </form>
    </div><!-- /panel-body-->

  </div><!-- panel -->
</template>
<script>
import {MAIN_INFO_STEP, auth, /*validate,*/ getStudentCurrentClassInfo} from "@/common/utils";

export default {
  name: 'Auth',
  emits: ['next', 'back', 'error', 'loading', 'stopLoading'],
  props: {
    geoLocationPermissionStatus: String,
    permissionsApiAvailable: Boolean,
    occurrenceId: Number,
    classroomCode: String,
    dateString: String,
    timeString: String
  },
  data() {
    return {username: '', password: '', error: null}
  },
  methods: {
    async next() {
      this.$emit('loading')
      try {
        if(!this.username || !this.password){
          return this.$emit('error', {message: 'Ingrese usuario y contraseña', code: ''})
        }
        const userAndToken = await auth(this.username, this.password);
        const class2Register = await getStudentCurrentClassInfo(userAndToken.token, this.occurrenceId, this.classroomCode)
        if (!class2Register) {
          return this.$emit('error', {message: 'No se pueden obtener datos de la clase', code: 'no_class_assigned'})
        }
        if(!this.occurrenceId && (this.dateString !== class2Register.date || this.timeString !== class2Register.time)) {
          return this.$emit('error', {message: 'Código QR no válido', code: 'invalid_qr'})
        }
        this.$emit('next', {goto: MAIN_INFO_STEP, token: userAndToken.token, user: { name: userAndToken.username }, class2Register, occurrenceId: class2Register.occurrenceId})
      } catch (e) {
        if('invalid_credentials' === e.code || 101 === e.code || 100 === e.code) {
          this.showErrorOnToast(e)
        } else {
          if(!e.code) {
            e.code = 'data_error'
          }

          this.$emit('error', e)
        }
      } finally {
        this.$emit('stopLoading')
      }
    },
    showErrorOnToast(error) {
      this.error = error;
      setTimeout(() => { this.error = null }, 3000)
    }
  }
}
</script>
