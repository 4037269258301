<template>
  <div class="container mt-3">
    <div class="wrap row w-full"><!-- wrap -->
      <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1"><!-- col -->
        <div class="heading-logo pb-3"><!-- heading-logo -->
          <div class="text-center">
            <img :src="`images/logo-${$clientCode}.png`" :alt="$clientName">
          </div>
        </div>
        <div v-show="!loading && !error">
          <component
              @next="onNext"
              @back="onBack"
              @error="onError"
              @loading="loading = true"
              @stopLoading="loading = false"
              ref="currentForm" :is="currentStep"
              v-bind="currentProperties"/>
        </div>
        <div class="alert alert-danger alert-dismissible" v-if="error && !currentErrorComponent" role="alert">
          {{ error.message }} {{ error.code ? `(${error.code})` : '' }}
          <button class="btn-close" @click="clearError"></button>
        </div>
        <div v-if="currentErrorComponent">
          <component @back="clearError" :is="currentErrorComponent"/>
          <div class="alert alert-danger" v-if="error" role="alert">
            {{ error.message }}
          </div>
        </div>
        <div class="loader" v-if="loading"></div>

        <pre v-if="showDebugInfo" style="font-size: smaller; overflow: scroll">
          {{ JSON.stringify($data, null, 2) }}
        </pre>
      </div><!-- /col -->
    </div><!-- wrap -->
  </div>
</template>

<script>
import Auth from "@/components/01-Auth";
import MainInfoDisplay from "@/components/02-MainInfoDisplay";
import AskForGeolocationPermissions from "@/components/03-AskForGeolocationPermissions";
import Register from "@/components/04-Register";
import Success from "@/components/05-Success";
import GeolocationPermissionsDenied from "@/components/06-GeolocationPermissionsDenied"
import InvalidQr from "@/components/errors/InvalidQr";
import NoClassInfo from "@/components/errors/NoClassInfo";
import DataError from "@/components/errors/DataError";
import NoClassAssigned from "@/components/errors/NoClassAssigned";

import {
  AUTH_STEP, CLASS_2_REGISTER_STRUCT,
  extractQueryParameters,
  GEOLOCATION_PERMISSION_GRANTED,
  GEOLOCATION_PERMISSION_PROMPT, getLocation,
  MAIN_INFO_STEP,
  REGISTER_STEP,
  SUCCESS_STEP
} from "@/common/utils"


export default {
  name: 'App',
  data() {
    return {
      token: '',
      user: {
        name: '',
        lastname: ''
      },
      currentStepIndex: 0,
      previousStepIndex: null,
      steps: ['Auth', 'MainInfoDisplay', 'AskForGeolocationPermissions', 'Register', 'Success', 'GeolocationPermissionsDenied'],
      errorComponents: {
        'invalid_qr': 'InvalidQr',
        'no_class_info': 'NoClassInfo',
        'data_error': 'DataError',
        'no_class_assigned': 'NoClassAssigned'
      },
      loading: false,
      error: null,
      class2Register: CLASS_2_REGISTER_STRUCT,
      position: {
        latitude: null, longitude: null
      },
      geoLocationPermissionStatus: null,
      permissionsApiAvailable: null,
      showDebugInfo: false,
      occurrenceId: 0,
      classroomCode: '',
      staticQr: Boolean,
      dateString: '',
      timeString: ''
    }
  },
  components: {
    Auth,
    MainInfoDisplay,
    AskForGeolocationPermissions,
    Register,
    Success,
    GeolocationPermissionsDenied,
    InvalidQr,
    NoClassInfo,
    DataError,
    NoClassAssigned
  },
  computed: {
    currentStep() {
      return this.steps[this.currentStepIndex]
    },
    currentErrorComponent() {
      if (!this.error?.code) return null
      let errorComponent = this.errorComponents[this.error.code]
      console.log(`errorComponent is: ${errorComponent}`)
      return errorComponent
    },
    currentProperties() {
      if (MAIN_INFO_STEP === this.currentStepIndex) {
        return {
          position: this.position,
          class2Register: this.class2Register,
          user: this.user,
          geoLocationPermissionStatus: this.geoLocationPermissionStatus,
          permissionsApiAvailable: this.permissionsApiAvailable
        }
      } else if (REGISTER_STEP === this.currentStepIndex || SUCCESS_STEP === this.currentStepIndex) {
        return {
          class2Register: this.class2Register,
          geoLocationPermissionStatus: this.geoLocationPermissionStatus,
          position: this.position,
          token: this.token,
          occurrenceId: this.occurrenceId,
          classroomCode: this.classroomCode,
          staticQr: this.staticQr,
        }
      } else if (AUTH_STEP === this.currentStepIndex) {
        return {
          geoLocationPermissionStatus: this.geoLocationPermissionStatus,
          permissionsApiAvailable: this.permissionsApiAvailable,
          occurrenceId: this.occurrenceId,
          classroomCode: this.classroomCode,
          dateString: this.dateString,
          timeString: this.timeString
        }
      }
      return {}
    }
  },
  methods: {
    next() {
      this.$refs.currentForm.next()
    },
    async checkGeoLocationPermissions() {
      const permissionStatus = await navigator?.permissions?.query({name: 'geolocation'})
      if (permissionStatus && permissionStatus.state) {
        this.permissionsApiAvailable = true
        return permissionStatus?.state
      } else {
        this.permissionsApiAvailable = false
        return GEOLOCATION_PERMISSION_PROMPT
      }
    },
    onNext(data) {
      for (const key in data) {
        this.$data[key] = data[key]
      }
      if (data.goto !== undefined) {
        this.previousStepIndex = this.currentStepIndex;
        this.currentStepIndex = data.goto
      } else {
        this.previousStepIndex = null;
        this.currentStepIndex++
      }
    },
    onBack() {
      if (null !== this.previousStepIndex) {
        const tmp = this.currentStepIndex;
        this.currentStepIndex = this.previousStepIndex;
        this.previousStepIndex = tmp;
      } else {
        this.currentStepIndex--
      }
    },
    onError(error) {
      this.error = error
    },
    clearError() {
      this.error = null
    }
  },
  async mounted() {
    let urlSearchParams = extractQueryParameters()
    this.geoLocationPermissionStatus = await this.checkGeoLocationPermissions()
    if (GEOLOCATION_PERMISSION_GRANTED === this.geoLocationPermissionStatus) {
      try {
        this.position = await getLocation()
      } catch (e) {
        this.error = e
      }
    }
    this.showDebugInfo = 'true' === urlSearchParams.get('showDebugInfo')
    this.occurrenceId = urlSearchParams.get('occurrenceId')
    if (this.occurrenceId) {
      this.staticQr = false
      this.occurrenceId = parseInt(this.occurrenceId)
    } else {
      this.staticQr = true
      this.dateString = urlSearchParams.get('date')
      this.timeString = urlSearchParams.get('time')
    }
    this.classroomCode = urlSearchParams.get('classroomCode')
  }
}
</script>

