<template>
  <div class="panel error border shadow-sm rounded-3 p-0"><!-- panel -->

    <div class="content-head px-4 pt-5 pb-4 text-center"><!-- content-head -->
      <h3 class="text-center pb-3">Algo salió mal al enviar <br>los datos</h3>
      <i class="fas fa-paper-plane pb-2 danger"></i>
    </div><!-- /content-head -->

    <div class="content-body msg-error px-4 pb-5"><!-- content-body -->
      <div class="content-title mb-5">
        <p class="pb-4 text-center">Por favor vuelve a la pantalla anterior y reintenta el envío.</p>
        <p class="pb-3 text-center">Si el error persiste comunícate con <br>el docente.</p>
      </div>
      <div class="d-grid mt-4">
          <button class="btn btn-outline-primary" @click="back">Volver</button>
      </div>
    </div><!-- /content-body -->

  </div><!-- panel -->
</template>
<script>
export default {
  name: 'DataError',
  emits: ['back'],
  methods: {
    back() {
      this.$emit('back')
    }
  }
}
</script>
