import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import { Replay } from '@sentry/replay';

export function createSentry(app) {
    const config = {
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing({ tracingOrigins: ["*"] }), new Replay({
            maskAllText: false,
            blockAllMedia: true,
            tracingOrigins: ["*"]
            }
        )],
        tracesSampleRate: 0.5,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: process.env.VUE_APP_SENTRY_CONFIG_RELEASE,
        tags: {
            app: process.env.VUE_APP_SENTRY_TAG_APP,
            environment: process.env.VUE_APP_SENTRY_TAG_ENVIRONMENT,
        },
    };

    Sentry.init(config);
    app.config.errorHandler = (error, vm, info) => {
        Sentry.withScope((scope) => {
            scope.setExtras(info);
            scope.setTag("origin", "QR");
            Sentry.captureException(error);
        });
    };
}
