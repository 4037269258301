<template>
  <div class="panel error border shadow-sm rounded-3 p-0"><!-- panel -->

    <div class="content-head px-4 pt-5 pb-4 text-center"><!-- content-head -->
      <h3 class="text-center pb-3">Algo salió mal</h3>
      <i class="fas fa-window pb-2 danger"></i>
    </div><!-- /content-head -->

    <div class="content-body msg-error px-4 pb-5"><!-- content-body -->
      <div class="content-title mb-5">
        <p class="pb-4 text-center">No es posible obtener los datos de la clase.</p>
        <p class="pb-3 text-center">Si crees que existe un error comunícate con el docente.</p>
      </div>
    </div><!-- /content-body -->

  </div><!-- panel -->
</template>
<script>
export default {
  name: 'NoClassInfo',
  emits: ['back'],
}
</script>
