<template>
  <div class="panel error border shadow-sm rounded-3 p-0"><!-- panel -->

    <div class="content-head px-4 pt-5 pb-4 text-center"><!-- content-head -->
      <h3 class="text-center pb-3">El código QR no es válido</h3>
      <i class="fas fa-qrcode pb-2 danger"></i>
    </div><!-- /content-head -->

    <div class="content-body msg-error px-4 pb-5"><!-- content-body -->
      <div class="content-title mb-5">
        <p class="pb-4 text-center">Los códigos poseen un tiempo de validez, intenta escanear nuevamente el código.</p>
        <p class="pb-3 text-center">Si el problema persiste comunícate con <br>el docente.</p>
      </div>
    </div><!-- /content-body -->

  </div><!-- panel -->
</template>
<script>
export default {
  name: 'InvalidQr',
  emits: ['back'],
}
</script>
