<template>
  <div class="panel border shadow-sm rounded-3 p-0"><!-- panel -->

      <div class="content-head px-4 pt-5 pb-4 text-center"><!-- content-head -->
        <h3 class="text-center pb-4">Ubicación</h3>
        <i class="fas fa-map-marker-alt pb-2 success"></i>
      </div><!-- /content-head -->

      <div class="content-body px-4 pb-5 text-center"><!-- content-body -->
        <p class="pb-5 mb-5">Para ayudar a determinar tu asistencia presencial necesitamos que nos permitas usar la ubicaci&oacute;n de tu dispositivo.</p>
        <div class="d-grid mt-5">
          <button class="btn btn-primary" @click="next">Permitir usar tu ubicaci&oacute;n</button>
        </div>
      </div><!-- /content-body -->

  </div><!-- panel -->
</template>
<script>
import {GEOLOCATION_PERMISSION_DENIED, GEOLOCATION_PERMISSION_GRANTED} from "@/common/utils";

  export default {
    name: 'AskForGeolocationPermissions',
    emits: ['next', 'back', 'error', 'loading', 'stopLoading'],
    props: {
      geoLocationPermissionStatus: String,
    },
    methods: {
      next() {
        if(GEOLOCATION_PERMISSION_DENIED !== this.geoLocationPermissionStatus) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                this.$emit('next', {geoLocationPermissionStatus: GEOLOCATION_PERMISSION_GRANTED, position: {latitude: position.coords.latitude, longitude: position.coords.longitude}})
              }, (error) => {
                if(error.PERMISSION_DENIED === error.code) {
                  this.$emit('next', {
                    geoLocationPermissionStatus: GEOLOCATION_PERMISSION_DENIED
                  });
                } else {
                  this.$emit('error', error)
                }
              }
          )
        } else {
          this.$emit('next', {})
        }
      }
    },
  }
</script>
