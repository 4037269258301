<template>
  <table class="table border-top table-sm table-borderless">
    <tr v-if="!hideCourseName">
      <th class="border-bottom py-2 ps-2">Clase:</th>
      <td class="border-bottom py-2 pe-2">{{ class2Register.courseName }}</td>
    </tr>
    <tr>
      <th class="border-bottom py-2 ps-2">Sala / Ubicación:</th>
      <td class="border-bottom py-2 pe-2">{{ class2Register.campusLocation }}</td>
    </tr>
    <tr>
      <th class="border-bottom py-2 ps-2">Profesor:</th>
      <td class="border-bottom py-2 pe-2">{{ class2Register.profesorName }}</td>
    </tr>
    <tr>
      <th class="border-bottom py-2 ps-2">Sección / NCR:</th>
      <td class="border-bottom py-2 pe-2">{{ class2Register.section }}</td>
    </tr>
    <tr>
      <th class="border-bottom py-2 ps-2">Fecha:</th>
      <td class="border-bottom py-2 pe-2">{{ class2Register.classDate }}</td>
    </tr>
    <tr>
      <th class="border-bottom py-2 ps-2">M&oacute;dulo:</th>
      <td class="border-bottom py-2 pe-2">{{ class2Register.module }}</td>
    </tr>
    <tr v-if="geoLocationPermissionGranted || geoLocationPermissionDenied">
      <th class="border-bottom py-2 ps-2">Ubicación:</th>
      <td class="border-bottom py-2 pe-2">
        <span v-if="geoLocationPermissionDenied" class="bullet bullet--deny" >
          Bloqueada
          <a class="small btn_edit" href="" v-if="showGeolocationPermissionsChangeLink" @click.prevent="changeGeoLocationPermissions"><i class="fas fa-pen"></i>Cambiar</a>
        </span>
        <span v-else class="bullet bullet--allow" >
          Permitida
        </span>
      </td>
    </tr>
  </table>
</template>
<script>
import {
  CLASS_2_REGISTER_STRUCT,
  GEOLOCATION_PERMISSION_DENIED,
  GEOLOCATION_PERMISSION_GRANTED,
  GEOLOCATION_PERMISSIONS_DENIED_STEP
} from "@/common/utils";

export default {
  name: 'ClassInfo',
  emits: ['next'],
  props: {
    class2Register: CLASS_2_REGISTER_STRUCT,
    hideCourseName: Boolean,
    showGeolocationPermissionsChangeLink: Boolean,
    geoLocationPermissionStatus: String,
  },
  methods: {
    changeGeoLocationPermissions() {
      this.$emit('next', {goto: GEOLOCATION_PERMISSIONS_DENIED_STEP})
    }
  },
  computed: {
    geoLocationPermissionDenied() {
      return GEOLOCATION_PERMISSION_DENIED === this.geoLocationPermissionStatus
    },
    geoLocationPermissionGranted() {
      return GEOLOCATION_PERMISSION_GRANTED === this.geoLocationPermissionStatus
    }
  },
}
</script>
